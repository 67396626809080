












































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { DutyHistoryEntityModel, DutyHistoryQueryModel } from '@common-src/entity-model/duty-history-entity';
import DutyHistoryService from '@common-src/service/duty-history';

@Component
export default class DutyHistoryListComponent extends TableDialogFormComponent<DutyHistoryEntityModel, DutyHistoryQueryModel> {
    DutyHistoryEntityModel = DutyHistoryEntityModel;
    created() {
        this.initTable({
            service: DutyHistoryService,
            queryModel: new DutyHistoryQueryModel(),
            tableColumns: DutyHistoryEntityModel.getTableColumns()
        });
        this.getList();
    }

    exportReport() {
        // 导出报表
    }
}

